@import "../../../App.scss";

.content-color {
    color: #696873;
}
.count-color {
    color: $heading;
}

.my-learning-holder {
    margin-left: 10px;
    margin-bottom: 20px;
    width: 90%;
    font-family: Roboto;
    font-style: normal;
    h3 {
        color: #333269;
        padding-bottom: 10px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        aside {
            cursor: pointer;
            text-decoration: underline !important;
            float: right;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #3f51b5;
        }
    }
    h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #222222;
    }
    .subject-heading {
        background: #c0beff33;
        color: $heading;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 15px;
        padding: 22px;
    }
    .learning-introduction {
        margin-top: 30px;
        // height: 246px;
        border-radius: 8px;
        padding: 20px 40px;
        color: #fff;
        font-family: Roboto;
        h4 {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: $heading;
        }
        .description {
            width: 75%;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;
            color: #101828;
            margin-top: 10px;
            margin-bottom: 15px;
        }
        .view-btn {
            background-color: #3649f9;
            color: #fff;
            border: none;
            width: 120px;
            height: 38px;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: center;
            border-radius: 4px;
        }
    }
    .skill-library {
        background-color: #fff;
        padding: 25px 25px 15px 25px;
        color: #222222;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
    }
}

.bubble-card {
    width: 100%;
    margin-left: -15px;
    position: relative;
    .slick-track {
        display: flex !important;
        align-items: stretch;
    }

    .slick-slide {
        margin-right: 10px;
        // width: 174px !important;
    }
    .slick-disabled {
        opacity: 0 !important;
    }
    .slick-next {
        right: 25px;
        top: 35%;
        width: 0px;
        height: 0px;
        z-index: 1;
        position: absolute;
        cursor: pointer;
        &:before {
            font-size: 0px;
            opacity: 0;
            display: none;
        }
    }
    .slick-prev {
        left: -25px;
        top: 35%;
        width: 0px;
        height: 0px;
        z-index: 1;
        position: absolute;
        cursor: pointer;
        &:before {
            opacity: 0;
            display: none;
            font-size: 0px;
        }
    }
    .card {
        font-family: Roboto;
        padding: 0px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        min-height: 165px;
        margin: 10px;
        margin-top: 0px;
        margin-right: -10px;
        border-radius: 8px;
        // width: 175px !important;
        box-shadow: 0px 3px 8px rgba(38, 38, 38, 0.2);
        .card-img-top {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        .time {
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0px;
            text-align: left;
            color: #667085;
            background-color: #ffffff;
            position: absolute;
            top: 10px;
            right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6.5px;
            border-radius: 4px;
        }
        .card-body {
            font-family: Roboto;
            font-style: normal;
            min-height: 88px;
            background-color: #fff;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            h5 {
                font-family: Roboto;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #101828;
                height: 40px;
            }
            p {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: 0px;
                text-align: left;
                color: #5855d6;
            }
            button {
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: $button-color;
                background: transparent;
                border: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .my-learning-holder {
        width: unset;
        margin-left: 0px;
        .learning-introduction {
            border-radius: 0px;
            padding: 20px 30px;
            .description {
                width: 100%;
            }
        }
        .skill-library-holder {
            width: 100% !important;
            margin-bottom: 25px !important;
            margin-top: -10px;
            h3 {
                font-size: 24px;
            }
            .skill-library {
                padding-left: 22px !important;
                margin-left: -20px;
                .empty-list {
                    p {
                        font-size: 13px;
                    }
                }
                .sem-header {
                    ul {
                        padding-left: 0px !important;
                        li {
                            width: 10% !important;
                        }
                    }
                }
                .bubble-card {
                    width: 100%;
                    .card {
                        min-width: 195px;
                        // min-height: 150px;
                        margin-right: 0px;
                    }
                }
            }

            .program-card {
                margin-left: 16px;
                cursor: pointer;

                .cards {
                    position: relative;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    min-width: 195px;
                    word-wrap: break-word;
                    border-radius: 0.25rem;
                    margin-right: 10px;
                }
            }
            .library-card {
                background-color: transparent;
                box-shadow: none;
                width: 93% !important;
                padding: 18px;
                margin-left: 0px;
                h1 {
                    margin-top: 0px;
                }
                .cards {
                    margin-bottom: 20px;
                }
            }
        }
        .explore-subject {
            margin-left: 15px;
            padding: 10px 15px;
        }
        .bubble-card {
            .card {
                width: 230px;
            }
        }
    }
}

.confirm1 {
    width: 332px;
    h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
    }
    button {
        border: none;
        border-radius: 8px;
        background: #00b24b;
        color: #fff;
        width: 96px;
        height: 32px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
    }
}

.breadcrumb {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0.75rem 0rem;
    margin-bottom: 0rem;
    list-style: none;
    background-color: transparent !important;
    border-radius: 0.25rem;
    .breadcrumb-item {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;

        margin-top: -8px;
        color: $button-color;
        a {
            color: #9a9a9a;
        }
    }
}
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    margin-top: -5px;
    content: ".";
}
.b-active {
    font-weight: 600 !important;
}
.playlist-details {
    margin-left: -320px;
}
a:hover {
    color: #000000;
}

.empty-list {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px;
    margin-top: 8px;
    img {
        width: 350px;
        height: 210px;
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        margin-top: 15px;
        color: #696873;
    }
}

.sem-header button.tab-button {
    display: none;
}
@media (max-width: 767px) {
    .playlist-details {
        margin-left: -20px !important;
    }
    .breadcrumb .breadcrumb-item {
        font-size: 12px;
    }

    .topic-loader {
        width: 100%;
    }
}
@media (min-width: 414px) and (max-width: 736px) {
    .chapter-topic-details {
        .upper-details {
            .content-count {
                margin-right: 20px;
            }
            .content-count1 {
                margin-right: 23px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .skill-library-holder .skill-library .sem-header ul li {
        width: 5.5%;
        margin-right: 10px;
    }
}

///
.checkmark {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    stroke-width: 5;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation:
        fill 0.4s ease-in-out 0.4s forwards,
        scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 7;
    stroke-miterlimit: 10;
    stroke: $button-color;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    stroke: $button-color;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }
    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px $button-color;
    }
}
