@import "../../../App.scss";

.no-spin::-webkit-outer-spin-button,
.no-spin::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
}
/* Firefox */
.no-spin {
    -moz-appearance: textfield;
}

.alumani-modal {
    .edit-educational-container {
        padding: 25px;
        position: relative;
        .cross-holder {
            position: absolute;
            right: 15px;
            cursor: pointer;
        }
        .profile-avatar-edit-holder {
            text-align: center;
            width: 200px;
            margin: 0 auto;
        }
        h2 {
            font-size: 24px;
            margin-bottom: 29px;
        }
        .save-buttons-education {
            text-align: right;
            margin-top: 25px;
            padding-right: 10px;
            button {
                width: 35%;
                border: none;
                background-color: $button-color;
                border-radius: 4px;
                color: #fff;
                padding: 15px;
            }
        }
    }

    .edit-educational-container {
        .educational-details-main {
            .save-buttons-education {
                padding: 0;
            }
        }
    }
}

.user-details-holder {
    h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 30px;
        color: #484750;
    }
    .profile-holder {
        margin-top: 20px;
        background-color: #fff;
        padding-bottom: 35px;
        width: 70%;
        padding-left: 50px;
        padding-top: 25px;
        h3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $chart-color;
        }
        p {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            margin-left: 24px;
            cursor: pointer;
        }
        label {
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
        }
        .save-btn {
            // padding: 15px;
            // text-align: center;
            margin-top: 0px;
            position: relative;
            button {
                margin-right: 10px;
                height: 44px;
                width: unset;
                border-radius: 4px;
                font-family: Roboto;
                position: relative;
                padding: 0px 15px;
            }
        }
        .profile-container {
            margin-left: 20%;
            position: relative;
            .profile-info-avatar {
                width: 130px;
                height: 130px;
                border: 2px solid #fff;
                position: relative;
                overflow: hidden;
                //   margin: 0 auto;
                border-radius: 50%;
                font-weight: bold;
                font-size: 40px;
                line-height: 48px;
                //   box-shadow: 0px 0px 11px 2px #00000042;
                img {
                    width: 100%;
                    height: 130px;
                }
            }
        }
    }
    .subscription-details {
        width: 70%;
        margin-top: 10px;
        background-color: #fff;
        padding-left: 50px;
        padding-top: 25px;
        padding-bottom: 35px;
        h3 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $dark-background;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        button {
            border: none;
            background-color: $button-color;
            color: #fff;
            padding: 10px 50px;
            border-radius: 4px;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
        }
    }
}

@media (max-width: 767px) {
    .user-details-holder {
        margin-bottom: 30px;
        .profile-holder {
            width: 96%;
            padding-left: 21px;
            padding-right: 20px;
            .profile-container {
                margin-left: 30%;
            }
            .save-btn {
                margin-top: 15px;
                text-align: center;
                button {
                    margin-right: 0px;
                }
            }
        }
        .subscription-details {
            width: 96%;
            padding-left: 21px;
            padding-right: 20px;
        }
    }
}
