@import "../../../../App.scss";

.manage-subjects {
    width: 90%;
    margin-left: 20px;
    h1 {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #222222;
    }
    .upper-details {
        margin-top: 5px;
        background-color: #fff;
        padding: 10px 25px;
        // border-bottom: 5px solid #f6f6f6;
        display: flex;
        position: relative;
        justify-content: space-between;
        align-items: center;
        font-family: Source Sans Pro;
        font-style: normal;
        font-weight: normal;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        .content-count {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-right: 25px;
            p {
                font-size: 32px;
                font-family: Lato;
                color: $dark-background;
            }
            span {
                font-size: 14px;
                color: #696873;
            }
            img {
                width: 27px;
                height: 27px;
            }
        }
    }
    .save-schedule {
        color: #fff;
        background: $button-color;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        width: 120px;
        height: 32px;
        border: none;
        border-radius: 4px;
    }
    .subject-header {
        background: #fff;
        padding: 18px;
        margin-top: 8px;
        padding-bottom: 5px;
        position: relative;
        border-bottom: 2px solid $chart-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        .schedule {
            border: none;
            background: none;
            color: $button-color;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
        }
        button {
            background: $button-color;
            border: none;
            width: 200px;
            height: 40px;
            border-radius: 4px;
            color: #fff;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 18px;
            img {
                width: 14px;
            }
        }
        ul {
            list-style: none;
            margin-top: 20px;
            li {
                display: flex;
                // text-align: center;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: $chart-color;
                cursor: default;
                div:first-child {
                    width: 10%;
                    padding-left: 25px;
                    color: $chart-color;
                }
                div:nth-child(2) {
                    width: 30%;
                    color: $chart-color;
                    margin-left: 35px;
                }
                div {
                    &:nth-child(3),
                    &:nth-child(4),
                    &:nth-child(5),
                    &:nth-child(6),
                    &:nth-child(7),
                    &:nth-child(8) {
                        width: 20%;
                        color: $chart-color;
                        text-align: center;
                    }
                }
            }
        }
    }

    .empty-list {
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 40px;
        margin-top: 8px;
        img {
            width: 350px;
            height: 210px;
        }
        p {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            margin-top: 15px;
            color: #696873;
        }
    }
    .subject-list {
        list-style: none;
        margin-top: 7px;
        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            padding: 13px 22px;
            cursor: pointer;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            margin-bottom: 8px;
            h3 {
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: #696873;
                font-family: Lato;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                .react-switch-handle {
                    height: 12px !important;
                    width: 12px !important;
                    top: 2px !important;
                }
            }
            .subject-name {
                width: 35%;
                margin-left: -10px;
                display: flex;
                align-items: center;
                img {
                    display: inline-block;
                    vertical-align: middle;
                }
                p {
                    display: inline-block;
                    margin-left: 10px;
                    width: 70%;
                }
            }
            .subject-num {
                width: 10%;
                padding-left: 20px;
                img {
                    display: inline-block;
                    vertical-align: bottom;
                    margin-left: -30px;
                    cursor: grab;
                }
                p {
                    display: inline-block;
                    margin-left: 12px;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #696873;
                    font-family: Lato;
                }
            }
            div {
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5),
                &:nth-child(6),
                &:nth-child(7) {
                    width: 20%;
                }
                img {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
            div:nth-child(8) {
                width: 20%;
                text-align: center;
            }
        }
    }
    .add-subject-brief {
        background-color: #fff;
        padding: 20px;
        label {
            font-family: Roboto;
            font-weight: 600;
        }
        .border {
            border: 1px solid #c0beff;
            padding: 15px;
            border-radius: 4px;
            padding-right: 35px;
            // box-shadow: 0px 4px 15px 0px #0000001a;
        }
        .css-b62m3t-container {
            .css-1okebmr-indicatorSeparator {
                display: none;
            }
        }
        .css-b62m3t-container .css-13cymwt-control {
            border: 1px solid $chart-color;
            border-radius: 4px;
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
        }
        .css-t3ipsp-control {
            .css-1u9des2-indicatorSeparator {
                display: none;
            }
        }
        .add-btn {
            background: none;
            border: none;
            box-shadow: none;
            color: $button-color;
            margin-top: 10px;
        }
        .remove-btn {
            background: none;
            border: none;
            box-shadow: none;
            height: 0px;
            position: absolute;
            top: 7px;
            right: -35px;
        }
    }
}

#popover-contained1 {
    transform: translate(405px, 401px) !important;
    .show-modal {
        width: 300px;
        height: 230px;
        background: $darker-background;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 16px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            top: 20%;
            left: 0%;
            margin-left: -13px;
            border-width: 13px;
            border-style: solid;
            border-color: $darker-background transparent transparent transparent;
        }
        .pro-text {
            width: 75%;
            margin-left: 10px;
            h2 {
                color: #fff;
                font-family: Source Sans Pro;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
            }
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                text-align: justify;
            }
        }
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #fff;
        }
        button {
            margin-top: 10px;
            background-color: $button-color;
            color: #fff;
            width: 224px;
            height: 40px;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            border: none;
            border-radius: 4px;
        }
    }
}

.tooltip-arrow {
    display: none !important;
}
.add-topic-modal {
    width: 29%;
    .modal-content {
        border-radius: 4px;
        border: none;
    }
    .modal-header {
        position: relative;
        padding: 0.7rem 0.7rem;
        text-align: center;
        display: block;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        h5 {
            color: #fff;
            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 22px;
            p {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #fff;
                margin-top: 5px;
            }
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 25px;
            cursor: pointer;
        }
    }
    .modal-body {
        label {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            width: 100%;
            aside {
                font-weight: normal;
                font-size: 12px;
                line-height: 15px;
                color: #9b9aa3;
            }
        }
        input {
            border-color: $chart-color;
        }
        textarea {
            border-color: $chart-color;
        }
        .program-image {
            width: 139px;
            height: 78px;
            background-color: #f7f7fa;
            margin-top: 10px;
            margin-right: 15px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        span {
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: $button-color;
            margin-bottom: 10px;
            cursor: pointer;
            img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
            }
            .file-upload {
                position: absolute;
                left: 0px;
                height: 30px;
                width: 100%;
                opacity: 0;
                cursor: pointer;
                top: 0px;
            }
        }
        small {
            color: red;
            font-size: 12px;
        }
    }
}
hr {
    margin-right: 194px;
    margin-top: 44px;
}
