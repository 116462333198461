@import "../../../../App.scss";

.chapter-module-holder {
    margin-left: 5px;
    margin-bottom: 20px;
    width: 90%;
    font-family: Roboto;
    font-style: normal;
    .breadcrumb {
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding: 0.75rem 0rem;
        margin-bottom: 0rem;
        list-style: none;
        background-color: transparent !important;
        border-radius: 0.25rem;
        .breadcrumb-item {
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;

            margin-top: -8px;
            color: $button-color;
            a {
                color: #9a9a9a;
            }
        }
    }
    .breadcrumb-item + .breadcrumb-item::before {
        display: inline-block;
        padding-right: 0.5rem;
        color: #000000;
        font-size: 20px;
        font-weight: 600;
        margin-top: -5px;
        content: ".";
    }
    .chapter-description {
        background: #c0beff33;
        // height: 210px;
        padding: 20px;
        display: flex;
        .chapter-img {
            width: 32%;
            img {
                width: 260px;
                height: 174px;
            }
        }
        .chapter-desc {
            width: 68%;
            h3 {
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #413c69;
            }
            p {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #101828;
            }
            .sub-description {
                width: 95%;
                margin: 5px 0px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #272727;
            }
            .chapter-count {
                margin-top: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 70%;
                h5 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
                h6 {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
            }
        }
    }
    .course-description {
        background: #fff;
        // height: 210px;
        padding: 20px 24px;
        display: flex;
        box-shadow: 0px 3px 31px 0px #3c4dac26;
        .chapter-img {
            width: 22%;
            img {
                width: 345px;
                height: 247px;
            }
        }
        .chapter-desc {
            width: 61%;
            h3 {
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #413c69;
            }
            p {
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #101828;
            }
            .sub-description {
                width: 92%;
                margin: 5px 0px;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                color: #272727;
            }
            .chapter-count {
                margin-top: 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 70%;
                h5 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
                h6 {
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
            }
            .action-btn {
                margin-top: 25px;
                display: flex;
                align-items: center;
                button {
                    background-color: $button-color;
                    color: #fff;
                    border: none;
                    border-radius: 4px;
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: center;
                    padding: 10px 25px;
                }
                h5 {
                    cursor: pointer;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    letter-spacing: -0.02em;
                    color: $button-color;
                    margin-left: 20px;
                }
            }
        }
    }
    .chapters-holder {
        margin-top: 20px;

        h4 {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 500;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
            aside {
                cursor: pointer;
                text-decoration: underline !important;
                float: right;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #3f51b5;
            }
        }
    }
    .test-holder {
        // height: 204px;
        background-color: #fff;
        margin-top: 20px;
        border: 1px solid #0000001a;
        padding: 35px 30px;
        // display: flex;
        background-image: url("../../../../assets/cup.svg");
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 300px 190px;
        h4 {
            //styleName: h4;
            font-family: Roboto;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            color: #272727;
        }
        p {
            width: 40%;
            margin: 15px 0px;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: left;
            color: #444444;
        }
        button {
            height: 38px;
            color: #fff;
            background-color: $button-color;
            border-radius: 4px;
            border: none;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            padding: 0px 15px;
        }
    }
    .test-holder1 {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .test-card {
            height: 220px;
            box-shadow: 0px 3px 25px 0px #3c4dac1a;
            border: 1px solid #0000001a;
            background-color: #ffffff;
            padding: 20px;
            width: 32%;
            margin-right: 10px;

            &:nth-child(3) {
                margin-right: 0px;
            }
            h4 {
                font-family: Roboto;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #000000;
                margin: 5px;
            }
            p {
                height: 70px;
                width: 85%;
                font-family: Roboto;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: #444444;
                margin: 7px;
            }
            button {
                padding: 12px 25px;
                color: #ffffff;
                background-color: #5855d6;
                font-family: Roboto;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: -0.02em;
                text-align: left;
                border: none;
                border-radius: 4px;
                margin: 7px;
            }
        }
    }
    .mentors-list {
        margin-left: 11px;
        margin-top: 25px;
        .card {
            border: none;
            padding: 8px;
            background: #ffffff;
            border: 1px solid #0000001a;
            border-radius: 8px;
            .card-body {
                position: relative;
                padding-top: 5px;
                padding-bottom: 0px;
                h5 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: -0.01em;
                    text-align: center;
                    color: #0f172a;
                    height: 44px;
                }
                p {
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: -0.01em;
                    text-align: center;
                    color: #0f172a;
                    margin-bottom: 5px;
                }
            }
            .card-img-top {
                height: 150px;
                border-radius: 8px;
            }
            .elipsis {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            .elipsis1 {
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
}

@media (max-width: 767px) {
    .chapter-module-holder {
        width: unset;
        padding-left: 10px;
        padding-right: 12px;
        .course-description {
            .chapter-desc {
                h3 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    // color: #000000;
                }
                .chapter-count {
                    display: none;
                }
                .action-btn {
                    button {
                        display: none;
                    }
                    h5 {
                        margin-left: 0px;
                    }
                }
            }
            .chapter-img {
                img {
                    width: 135px;
                    height: 132px;
                }
            }
        }
        .chapter-description {
            padding: 15px;
            .chapter-img {
                width: 50%;
                img {
                    width: 125px;
                    height: 132px;
                }
            }
            .chapter-desc {
                h3 {
                    font-family: Roboto;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                    // margin-left: 100px;
                }
                .chapter-count {
                    display: none;
                }
            }
        }
        .chapters-holder {
            padding: 10px 5px;
        }
        .test-holder {
            padding: 25px;
            background-position: bottom right;
            background-size: 195px 120px;
            p {
                width: 75%;
            }
        }
        .test-holder1 {
            .test-card {
                height: unset;
                width: 100%;
                margin-right: 0px;
                margin-top: 10px;
            }
        }
    }
}

.subject-details {
    // width: 1194px;
    .modal-content {
        border-radius: 4px;
        border: none;
    }
    .modal-header {
        position: relative;
        padding: 30px 35px;
        padding-bottom: 20px;
        text-align: center;
        display: block;
        border-bottom: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;

        h5 {
            font-family: Roboto;
            font-size: 24px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: left;
            color: #000000;
        }
        .sticky-close {
            position: absolute;
            right: 13px;
            top: 22px;
            cursor: pointer;
        }
    }
    .modal-body {
        border: 1px solid #0000001a;
        margin: 0px 35px;
        margin-bottom: 30px;
        background: #f9f9ff;
        overflow: auto;
        max-height: 500px;
        &::-webkit-scrollbar {
            display: block;
        }

        & ::-webkit-scrollbar-track {
            border-radius: 2px;
            background-color: #413c69;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #d9d9d9;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            max-height: 6px;
            background-color: #413c69;
        }
        .course-description {
            background: #fff;
            margin-top: 10px;
            padding: 20px 24px;
            display: flex;
            box-shadow: 0px 3px 31px 0px #3c4dac26;
            .chapter-img {
                width: 22%;
                img {
                    width: 300px;
                    // height: 247px;
                }
            }
            .chapter-desc {
                width: 65%;
                h3 {
                    font-family: Roboto;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #413c69;
                }
                p {
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #101828;
                }
                .sub-description {
                    width: 92%;
                    margin: 5px 0px;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #272727;
                }
                .chapter-count {
                    margin-top: 25px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 70%;
                    h5 {
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000000;
                    }
                    h6 {
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000000;
                    }
                }
                .action-btn {
                    margin-top: 25px;
                    display: flex;
                    align-items: center;
                    button {
                        background-color: $button-color;
                        color: #fff;
                        border: none;
                        border-radius: 4px;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        padding: 10px 25px;
                    }
                    h5 {
                        cursor: pointer;
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                        letter-spacing: -0.02em;
                        color: $button-color;
                        margin-left: 20px;
                    }
                }
            }
        }
        .course-content {
            margin-top: 20px;
            display: flex;
            .course-desc {
                width: 73%;
                h1 {
                    font-family: Roboto;
                    font-size: 20px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
                ul {
                    list-style: disc;
                    margin-left: 25px;
                    margin-top: 10px;
                    li {
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000000;
                    }
                }
                .course-learn {
                    margin-top: 20px;
                    padding: 20px;
                    background-color: #c0beff33;
                    h2 {
                        font-family: Roboto;
                        font-size: 20px;
                        font-weight: 700;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #413c69;
                    }
                }
                .skills-list {
                    margin-top: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    .skill {
                        background: #eeedff4d;
                        border: 1px solid #8484844d;
                        // height: 37px;
                        // width: 124px;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 27px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #000000;
                        margin-right: 5px;
                        padding: 4px 12px;
                        &:nth-child(5) {
                            margin-right: 0px;
                        }
                    }
                }
                .topics-covered {
                    margin-top: 20px;
                    background-color: #fff;
                    border: 1px solid #0000001a;
                    border-radius: 4px;
                    .topic-header {
                        padding: 15px 18px;
                    }
                    .topic-cards {
                        padding: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        .card {
                            font-family: Roboto;
                            padding: 0px;
                            border-top-left-radius: 8px;
                            border-top-right-radius: 8px;
                            min-height: 165px;
                            margin: 10px;
                            margin-top: 0px;
                            margin-right: -10px;
                            // width: 175px !important;
                            box-shadow: 0px 3px 8px rgba(38, 38, 38, 0.2);
                            .card-img-top {
                                border-top-left-radius: 8px;
                                border-top-right-radius: 8px;
                            }
                            .time {
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 15px;
                                letter-spacing: 0px;
                                text-align: left;
                                color: #667085;
                                background-color: #ffffff;
                                position: absolute;
                                top: 10px;
                                right: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                padding: 6.5px 10px;
                                border-radius: 4px;
                            }
                            .card-body {
                                font-family: Roboto;
                                font-style: normal;
                                min-height: 88px;

                                h5 {
                                    font-family: Roboto;
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 20px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                    color: #101828;
                                    height: 40px;
                                }
                                button {
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 32px;
                                    letter-spacing: 0em;
                                    text-align: left;
                                    background-color: transparent;
                                    border: none;
                                    color: $button-color;
                                }
                            }
                        }
                    }
                }
                .start-btn {
                    margin-top: 20px;
                    button {
                        background-color: $button-color;
                        border-radius: 4px;
                        padding: 10px 27px;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.02em;
                        text-align: left;
                        color: #fff;
                        border: none;
                    }
                }
            }
            .course-job-desc {
                width: 25%;
                margin-left: 2%;
                .course-job {
                    box-shadow: 0px 4px 15px 0px #0000001a;
                    background-color: #fff;
                    padding: 18px;
                    h2 {
                        font-family: Roboto;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #000000;
                    }
                    ul {
                        list-style: disc;
                        margin-left: 25px;
                        margin-top: 15px;
                        li {
                            font-family: Roboto;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 19px;
                            letter-spacing: 0em;
                            text-align: left;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}

.alert-modal {
    width: 360px;
    .modal-body {
        p {
            padding: 5px 0px;
            //styleName: Body Text;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #000000;
        }

        button {
            background-color: $button-color;
            padding: 0px 30px;
            border-radius: 4px;
            height: 40px;
            color: #fff;
            border: none;
            font-family: Roboto;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
        }
    }
}
