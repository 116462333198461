$width: 100;
$white-background: #fff;

.ball-loader {
  width: $width + 0px;
  height: calc((100% / 3) - 10px);
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.ball-loader-ball {
  will-change: transform;
  height: calc(100 / 3) - 10px;
  width: calc(100 / 3) - 10px;
  border-radius: 50%;
  background-color: $white-background;
  position: absolute;
  animation: grow 1s ease-in-out infinite alternate;

  &.ball1 {
    left: 0;
    transform-origin: 100% 50%;
  }
  &.ball2 {
    left: 50%;
    transform: translateX(-50%) scale(1);
    animation-delay: 0.33s;
  }
  &.ball3 {
    right: 0;
    animation-delay: 0.66s;
  }
}

@keyframes grow {
  to {
    transform: translateX(-50%) scale(0);
  }
}
