@import "../../../App.scss";

.login-banner {
    .login-banner-image {
        margin-top: 20px;
        img {
            width: 75%;
            border-radius: 20px;
        }
    }
    .quotes {
        margin-top: 25px;
        .placeholder-img {
            margin-top: 15px;
            width: 72px;
            height: 72px;
            border-radius: 50%;
        }
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 32px;
            color: $chart-color;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 18px;
            color: $dark-background;
        }
        small {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: $dark-background;
        }
    }
}

.login-holder {
    position: relative;
    top: 25%;
    /* left: 6%; */
    display: flex;
    /* right: -41%; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .css-19bb58m {
        input {
            height: 34px !important;
            text-align: left !important;
        }
    }
    .css-qbdosj-Input {
        text-align: left !important;
        input {
            height: 34px !important;
            text-align: left !important;
        }
    }
    .css-b62m3t-container {
        .css-1okebmr-indicatorSeparator {
            display: none;
        }
    }
    .css-1dimb5e-singleValue {
        text-align: left !important;
    }
    .css-1nmdiq5-menu {
        text-align: left !important;
    }
    .css-1jqq78o-placeholder {
        text-align: left;
    }
    .css-b62m3t-container .css-13cymwt-control {
        border: 1px solid $chart-color;
        border-radius: 4px;
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }
    .css-b62m3t-container .css-t3ipsp-control {
        border: 1px solid $chart-color;
        border-radius: 4px;
        .css-1u9des2-indicatorSeparator {
            display: none;
        }
    }

    input {
        border: 1px solid $chart-color;
        height: 48px;
        &:focus {
            border-color: $chart-color;
            box-shadow: none;
        }
    }
    .has-search {
        position: relative;
        .form-control-feedback {
            position: absolute;
            z-index: 0;
            display: block;
            height: 2.375rem;
            line-height: 2.375rem;
            text-align: center;
            pointer-events: none;
            color: #aaa;
            margin-top: 4px;
            width: 24px;
            margin-left: 9px;
        }

        .form-control {
            padding-left: 2.3rem;
        }
    }

    .login-button {
        background-color: $button-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border: none;
        width: 100%;
        height: 48px;
    }
    .w-50 {
        width: 50% !important;
    }
    .elite-lang-select {
        display: flex;
        justify-self: flex-start;
        align-items: center;
        margin-top: 10px;
        div {
            width: 130px;
            height: 40px;
            background: #fff;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #535259;
            cursor: pointer;
            &:nth-child(2) {
                margin-left: 10px;
            }
            &:nth-child(3) {
                margin-left: 10px;
            }
        }
    }
    .forgot-btn {
        color: $button-color;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin-top: 10px;
        cursor: pointer;
        text-align: center;
    }
}
.error {
    text-align: left !important;
    color: red;
    font-size: 14px;
    margin: 5px 0px;
}
.otp-holder {
    width: 55%;
    text-align: center;
    h3 {
        font-size: 30px;
    }
    .primary-button {
        background-color: $button-color;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        border: none;
        width: 100%;
        height: 48px;
    }

    .otp-fields {
        p {
            margin-bottom: 20px;
        }
        input {
            height: 48px;
        }
        .form-group {
            input {
                margin-bottom: 10px;
            }
        }
    }
}

.primary-button {
    background-color: $button-color;
    position: relative;
}

@media (max-width: 767px) {
    .login-banner {
        display: none;
    }
    .otp-holder {
        width: 90%;
        .otp-input {
            width: 40px !important;
        }
    }
}

// Media Query

@media (max-width: 767px) {
    .banner-header-content {
        padding-right: 15px !important;
        padding-bottom: 15px;
    }

    .login-main-holder {
        padding: 1rem !important;

        .banner-header-login {
            .logo-main-holder {
                margin: 0;
            }
            h1 {
                display: none;
            }
            p {
                display: none;
            }
        }

        .login-holder {
            width: 100% !important;
            .form-group {
                span {
                    top: -8px !important;
                    line-height: 100%;
                    text-align: center;
                    width: 100%;
                }
                input {
                    font-size: 14px;
                }
            }
        }
        .banner-header-content {
            .banner-creative {
                display: none;
            }
        }
    }
}

@media (min-width: 768px) {
    .login-container {
        padding-top: 43px;
    }
}

@media (max-width: 480px) {
    .login-holder {
        .otp-fields {
            .otp-input {
                width: 36px !important;
                height: 36px;
                margin: 2px !important;
            }
        }
    }
}
