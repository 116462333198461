@import "../../../../App.scss";

.chapter-topic-details {
    width: 100%;
    margin-top: 10px;
    padding-left: 70px;
    padding-right: 70px;
    h1 {
        font-size: 24px;
    }
    .topic-header {
        padding: 16px;
        background-color: #333269;
        color: #fff;
        border-radius: 4px;
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
    }
    .chapter-topics {
        background-color: #fff;
        // box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
        padding: 7px 0 0;
        width: 27%;

        #accordian {
            .card {
                border: none;
                border-radius: 0px;
                padding: 1rem 0.5rem 1rem 0.3rem;
                &:not(:last-of-type) {
                    border-bottom: none;
                    border-radius: 0px;
                }
                border-bottom: 0px solid #f6f6f6 !important;
                .card-header {
                    padding: 15px 12px 10px 12px;
                }
                .card-body {
                    .card-title {
                        margin-bottom: 0px;
                    }
                }
                .recent-title {
                    padding: 15px 15px;
                }
                cursor: pointer;
                h1 {
                    span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                h5 {
                    font-family: Lato;
                    font-size: 14px;
                    color: #5855d6;
                }
            }
        }

        #accordian .card-header.active-bg2.collapsed:after {
            content: url("../../../../assets/arrow-down.svg");
        }
        #accordian .card-header:not(.collapsed)::after {
            content: url("../../../../assets/arrow-up.svg");
        }
        #accordian .card-header.collapsed:after {
            content: url("../../../../assets/arrow-down.svg");
            width: 15px;
            margin-left: 12px;
            float: left;
            right: 15px;
            top: 16px;
            position: absolute;
            font-family: Roboto;
        }
        #accordian .card-header:after {
            content: url("../../../../assets/arrow-up.svg");
            width: 15px;
            margin-left: 12px;
            float: left;
            right: 15px;
            top: 16px;
            position: absolute;
            font-family: Roboto;
        }

        #accordion .card-header.collapsed:after {
            content: url("../../../../assets/arrow-down.svg");
        }
        #accordion .card-header:not(.collapsed)::after {
            content: url("../../../../assets/arrow-up.svg");
        }
        #accordion .card-header.collapsed:after {
            content: url("../../../../assets/arrow-down.svg");
            width: 15px;
            margin-left: 12px;
            float: left;
            right: 15px;
            top: 12px;
            position: absolute;
            font-family: Roboto;
        }
        #accordion .card-header:after {
            content: url("../../../../assets/arrow-up.svg");
            width: 15px;
            margin-left: 12px;
            float: left;
            right: 15px;
            top: 12px;
            position: absolute;
            font-family: Roboto;
        }
        #accordion {
            .card {
                border: none;
                border-radius: 0px;
                padding: 0rem;

                &:not(:last-of-type) {
                    border-bottom: none;
                    border-radius: 0px;
                }
                border-bottom: 2px solid #f6f6f6 !important;
                .card-header {
                    background-color: #fff;
                    // padding: 10px 20px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-radius: 0px;
                    .card-title {
                        font-family: Roboto;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0em;
                        text-align: left;
                        color: #413c69;
                        margin-bottom: 0px;
                    }
                }
                cursor: pointer;
                h1 {
                    span {
                        font-family: "Roboto";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                h5 {
                    font-family: Lato;
                    font-size: 14px;
                    color: #5855d6;
                }
                .card-body {
                    padding: 0px 0px;
                }

                .resource {
                    .recent-title {
                        min-height: 30px;
                        overflow-x: hidden;
                        padding: 15px 15px 10px 20px;
                        position: relative;
                        width: 100%;
                        border-bottom: 1px solid #dee2e6;
                    }
                }
            }
        }
    }

    .chapter-content {
        height: 95vh;
        margin-left: 10px;

        position: relative;
        width: 73%;

        .accordian {
            background-color: #fff;
            padding: 10px 25px;
            margin-top: 5px;
            .card {
                border: none;
                // border-bottom: 5px solid #f6f6f6 !important;
            }
            .card-header:after {
                content: url("../../../../assets/arrow-up.svg");
                width: 15px;
                margin-right: 15px;
                float: left;
                margin-top: -28px;
                cursor: pointer;
                position: absolute;
                right: 0px;
            }
            .card-header.collapsed:after {
                /* symbol for "collapsed" panels */
                content: url("../../../../assets/arrow-down.svg");
                width: 15px;
                margin-right: 15px;
                margin-top: -28px;
                cursor: pointer;
                position: absolute;
                right: 0px;
            }
            .card-header {
                background-color: #fff;
                padding: 1rem 1.25rem;
                .card-title {
                    margin-bottom: 0px;
                    p {
                        font: normal normal 600 14px/18px Roboto;
                        color: #222222;
                        span {
                            font-size: 14px;
                            font-weight: normal;
                            line-height: 18px;
                        }
                    }
                }
            }
            .card-body {
                padding: 0rem;
            }
        }
        .chapter-header {
            background-color: #fff;
            padding: 11px 25px;
            h1 {
                display: flex;
                align-items: baseline;
                font-family: Roboto;
                font-size: 24px;
                font-weight: 700;
                line-height: 32px;
                letter-spacing: 0em;
                text-align: left;
                color: #000000;
                aside {
                    font-family: Roboto;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
            }
        }
        .skill-pod-topics {
            width: 100%;
            // margin-top: 2px;
            // border: 5px solid #ebe8fc;

            .content-holder {
                list-style: none;
                max-height: calc(100vh - 12rem);
                overflow-y: auto;
                & ::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: white;
                }
                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
                &:hover .resource:hover {
                    background: #ddd;
                }
            }
            .content-title {
                font-family: "Roboto";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #242326;
                width: 93%;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .content-tags {
                min-width: 75px;
                height: 24px;
                background-color: #e7c15f;
                border-radius: 12px;
                position: absolute;
                top: 15px;
                left: 24px;
                font-family: "Roboto";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #242326;
                padding: 5px;
            }
            .sticky-holder {
                background-color: #fff;
                top: 75px;
                position: sticky;
                position: -webkit-sticky;
                // height: calc(100vh - 100px);
                overflow-y: auto;
            }

            #resource-list {
                max-height: 370px;
                overflow: auto;
                overflow-x: hidden;
                &::-webkit-scrollbar {
                    display: block;
                }
                &::-webkit-scrollbar-track {
                    border-radius: 10px;
                    background-color: #fff;
                }
                &::-webkit-scrollbar {
                    width: 8px;
                    background-color: #ffffff;
                }
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    max-height: 10px;
                    background-color: #e9e8e8;
                }
            }
        }

        .topic-content {
            padding: 25px 35px;
            margin-top: 5px;
            background-color: #fff;
            .resource-header {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                h2 {
                    margin-top: -3px;
                }
                .prev-next {
                    width: 25%;
                    display: flex;
                    justify-content: space-between;
                    h6 {
                        cursor: pointer;
                        display: flex;
                        // align-items: center;
                        font-family: Roboto;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                }
            }
            .resource-description {
                margin-top: 20px;
                position: relative;
                min-height: 440px;
                .quiz-holder {
                    position: relative;
                    margin-top: 50px;
                    position: relative;
                    h1 {
                        font-size: 20px;
                    }
                    .question-header {
                        font-size: 18px;
                        margin-top: 10px;
                        margin-bottom: 10px;
                    }
                    .ques-title {
                        font-family: Roboto;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: #555555;
                        p {
                            min-height: 30px;
                            span {
                                line-height: 24px;
                            }
                        }
                    }
                    .desc {
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #555555;
                        margin-top: 10px;
                    }
                    .start-btn {
                        color: white;
                        background-color: $button-color;
                        border: none;
                        border-radius: 4px;
                        padding: 10px 40px;
                    }
                    ul {
                        list-style: none;
                        margin-top: 10px;
                        margin-right: -50px;
                        li {
                            width: 100%;
                            // display: flex;
                            // justify-content: center;
                            align-items: center;
                            .option {
                                border: 1px solid #ddd;
                                width: 71%;
                                min-height: 35px;
                                margin-top: 10px;
                                box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                                padding: 5px;
                                margin-left: 28px;
                                cursor: pointer;
                                margin-right: -10px;
                                color: #555555;
                                p {
                                    color: #555555;
                                    span {
                                        color: #555555;
                                    }
                                    img {
                                        vertical-align: middle;
                                    }
                                }
                            }

                            .option1 {
                                border: 1px solid #ddd;
                                width: 70%;
                                min-height: 35px;
                                margin-top: 10px;
                                box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                                padding: 5px;
                                margin-left: 29px;
                                cursor: pointer;
                                color: #555555;
                                p {
                                    color: #555555;
                                    span {
                                        color: #555555;
                                    }
                                    img {
                                        vertical-align: middle;
                                        &:hover {
                                            -ms-transform: scale(4); /* IE 9 */
                                            -webkit-transform: scale(4); /* Safari 3-8 */
                                            transform: scale(4);
                                            position: relative;
                                            /* right: -380px; */
                                            z-index: 9999;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .fixed-prev {
                        // position: fixed;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .next-quiz {
                            font-size: 28px;
                            // position: fixed;
                            bottom: 0px;
                            right: 0px;
                            width: 35%;
                            color: #fff !important;
                            background-color: $button-color;
                            padding: 10px;
                            font-size: 14px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        .prev-quiz {
                            font-size: 28px;
                            // position: fixed;
                            bottom: 0px;
                            left: 0px;
                            background-color: $button-color;
                            width: 35%;
                            font-size: 14px;
                            color: #fff;
                            padding: 10px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    .quiz-complete {
                        margin-top: -60px;
                        p {
                            font-family: Roboto;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 30px;
                            letter-spacing: 0px;
                            text-align: left;
                        }
                        small {
                            margin-top: 10px;
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0px;
                            text-align: left;
                        }
                        .score {
                            margin-top: 20px;
                            font-family: Roboto;
                            font-size: 64px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 80px;
                            letter-spacing: 0px;
                            text-align: left;
                        }
                        .start-btn {
                            color: white;
                            background-color: $button-color;
                            border: none;
                            border-radius: 4px;
                            padding: 10px 40px;
                            margin-top: 15px;
                        }
                    }
                }
                .correct {
                    background-color: #00cc4b;
                    color: #fff !important;

                    p {
                        color: #fff !important;
                        span {
                            color: #fff !important;
                        }
                        img {
                            vertical-align: middle;
                        }
                    }
                }
                .incorrect {
                    background-color: #fc3a3a;
                    color: #fff !important;
                    p {
                        color: #fff !important;
                        span {
                            color: #fff !important;
                        }
                        img {
                            vertical-align: middle;
                        }
                    }
                }
                .react-pdf__Page__textContent {
                    display: none;
                }
                .content-middle {
                    background: #fff;
                    border: 1px solid #ccc;
                    border-radius: 6px;
                    min-height: calc(100vh - 256px);
                    margin-top: 15px;
                    overflow-y: auto;
                    padding: 15px;
                    position: relative;
                    width: 85%;
                    & ::-webkit-scrollbar-track {
                        border-radius: 10px;
                        background-color: white;
                    }
                    &::-webkit-scrollbar {
                        width: 6px;
                        background-color: #ffffff;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        max-height: 10px;
                        background-color: #e9e8e8;
                    }
                    .tools {
                        position: absolute;
                        top: 10px;
                        button {
                            border: none;
                            background-color: transparent;
                            width: 40px;
                            height: 40px;
                            margin-right: 10px;
                            img {
                                width: 20px;
                            }
                            &:hover {
                                background-color: #f6f6f6;
                                border-radius: 50%;
                            }
                        }
                    }
                    .react-pdf__Document {
                        position: relative;
                        .zoom-btn {
                            position: absolute;
                            // right: 10px;
                            top: -10px;
                            z-index: 1;
                            button {
                                border: none;
                                background-color: transparent;
                                width: 45px;
                                height: 45px;
                                img {
                                    width: 20px;
                                }
                                &:hover {
                                    background-color: #f6f6f6;
                                    border-radius: 50%;
                                }
                            }
                        }
                        .react-pdf__Page {
                            span {
                                -webkit-user-select: none;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -o-user-select: none;
                                user-select: none;
                            }
                        }
                        .react-pdf__Page__annotations {
                            display: none;
                        }
                    }
                    .article-view {
                        button {
                            border: none;
                            background-color: $button-color;
                            color: #fff;
                            border-radius: 4px;
                            padding: 12px 40px;
                        }
                    }
                }
                .nextPage {
                    border: none;
                    background-color: #6457e8;
                    width: 25px;
                    height: 25px;
                    padding-bottom: 3px;
                    border-radius: 50%;
                    margin-top: 8px;
                }
            }
            .resource-like {
                margin-top: 15px;
                display: flex;
                align-items: center;
                .like-btn {
                    display: flex;
                    font-family: Roboto;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                    cursor: pointer;
                    img {
                        width: 19px;
                        height: 19px;
                        vertical-align: middle;
                    }
                    &:nth-child(2) {
                        margin-left: 20px;
                    }
                }
            }
            .resource-notes {
                margin-top: 30px;
                .addWidth {
                    // margin-top: -45px;
                    margin-left: -5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .removeWidth {
                    margin-top: 0px;
                    margin-left: -7px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
    .bubble-details {
        width: 100%;
        ul {
            list-style-type: none;
            li {
                padding: 10px 25px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #fff;
                border-bottom: 5px solid #f6f6f6;
                cursor: pointer;
                font-style: normal;
                font-family: Roboto;
                .bubble-title {
                    width: 35%;
                    p {
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #222222;
                    }
                }
                h2 {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #555555;
                    width: 25%;
                }
                .bubble-time {
                    width: 8%;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #555555;
                }
                .bubble-progress {
                    width: 15%;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .chapter-topic-details {
        width: unset;
        margin-top: 0px;
        // margin-right: -15px;
        padding-left: 7px;
        padding-right: 7px;
        margin-bottom: 50px;
        .chapter-topics {
            display: none;
        }
        .chapter-content {
            width: 100%;
            margin-left: 0px;
            height: unset;

            .accordion {
                padding: 0px;
                .topic-content {
                    padding: 15px 15px;
                    .resource-description {
                        min-height: 250px;
                        .quiz-holder {
                            ul {
                                margin-right: 0px;
                            }
                        }
                        .content-middle {
                            width: 100%;
                        }
                    }
                    .resource-header {
                        .prev-next {
                            display: none;
                        }
                    }
                    .prev-next-mobile {
                        margin-top: 30px;
                        display: flex;
                        justify-content: space-between;
                        h6 {
                            display: flex;
                            // align-items: center;
                            font-family: Roboto;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }
                }
            }

            .content-holder .resource .recent-title {
                margin-left: 0px;
                h1 {
                    font-size: 14px;
                }
            }
            .chapter-header-mobile {
                // height: 103px;
                background-color: #fff;
                padding: 15px;
                h1 {
                    font-family: Roboto;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #000000;
                }
                p {
                    font-family: Roboto;
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #82818c;
                }
                img {
                    vertical-align: middle;
                }
            }
        }
        .bubble-details {
            width: 100%;
            ul {
                list-style-type: none;
                li {
                    .bubble-title {
                        width: 65%;
                        span {
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 15px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: #999999;
                        }
                    }
                    .bubble-time {
                        width: unset;
                    }
                    .bubble-progress {
                        width: unset;
                    }
                    p {
                        font: normal normal 600 16px/20px Roboto;
                    }
                }
            }
        }
    }
}
