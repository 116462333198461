@import "../../../App.scss";

.report-holder {
    h2 {
        //styleName: Sub headings;
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    .report-tabs {
        display: flex;
        align-items: center;
        .report-tab {
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;
            color: $heading;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18%;
            height: 40px;
            padding: 12px;
            cursor: pointer;
        }
        .active {
            border-bottom: 2px solid #3f51b5;
        }
    }
    .report-filters {
        margin-top: 20px;
        display: flex;
        align-items: center;
        select {
            margin-left: 10px;
            width: 17%;
            border: 1px solid #5855d6;
            color: #3f51b5;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0em;
            text-align: left;
            background-image: url("../../../assets/arrow-down1.svg");
            background-position: 95% center;
        }
        .form-control {
            padding: 0rem 0.5rem;
        }
    }
    .school-reports {
        width: 95%;
        .center-graph {
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $heading;
            }
            select {
                width: 20%;
            }
            .legands {
                // width: 20%;

                .legand-1 {
                    display: flex;
                    align-items: center;
                    div {
                        width: 24px;
                        height: 24px;
                        background-color: #3f51b5;
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                    }
                }
            }

            .highcharts-data-label-color-0,
            .highcharts-data-label-color-1 {
                text {
                    fill: #696873 !important;
                    font-family: Lato;
                    font-size: 12px !important;
                    color: #696873 !important;
                }
            }
            .highcharts-xaxis-labels {
                text {
                    fill: #696873 !important;
                    font-family: Roboto;
                    font-size: 12px !important;
                    color: #696873 !important;
                }
            }
        }
        .program-graph {
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            .highcharts-title {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold !important;
                font-size: 18px !important;
                line-height: 24px;
                color: $heading !important;
                fill: $heading !important;
            }
            .highcharts-subtitle {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal !important;
                font-size: 14px !important;
                line-height: 24px;
                color: $heading !important;
                fill: $heading !important;
            }
            .highcharts-data-label {
                text {
                    fill: #696873 !important;
                    font-family: Roboto !important;
                    font-size: 14px !important;
                    color: #696873 !important;
                    font-style: normal;
                    line-height: 18px;
                    text-align: center;
                }
            }
            .highcharts-xaxis-labels text {
                font-family: Roboto !important;
                font-style: normal;
                font-weight: normal;
                font-size: 14px !important;
                line-height: 18px;
                color: #696873 !important;
                fill: #696873 !important;
            }
            .program-legand {
                width: 100%;
                display: flex;
                // justify-content:center;
                align-items: center;

                .legand {
                    height: 20px;
                    width: 20%;
                }
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    width: 80%;
                }
            }
        }
        .reports-header {
            background-color: #fff;
            margin-top: 10px;
            padding: 20px;
            position: relative;
            .content-1 {
                background-color: #fff;
                width: 180px;
                height: 80px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                padding: 16px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 15px;
                img {
                    width: 56px;
                    height: 56px;
                }
                h2 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #484750;
                    text-align: center;
                    p {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        color: #696873;
                    }
                }
            }
            .header-width {
                width: 68%;
                .css-2b097c-container {
                    .css-1okebmr-indicatorSeparator {
                        display: none;
                    }
                    .css-1pahdxg-control {
                        box-shadow: 0px 0px 0px 1px $chart-color !important;
                    }
                    input:focus {
                        box-shadow: none !important;
                    }
                }
            }
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $heading;
            }
        }
        .center-table {
            margin-top: 12px;
            background-color: #fff;

            .table td {
                padding: 0.75rem;
                vertical-align: top;
                border-top: 2px solid #dee2e6;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                &:first-child {
                    color: $button-color;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
            .table thead th {
                vertical-align: bottom;
                text-align: center;
                border-bottom: 0px solid #dee2e6;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: $heading;
                border-top: 0px solid #dee2e6;
                background: #c0beff33;
                border-bottom: 0;
                border-top: none;
                padding: 10px;
            }
        }
        .student-graph {
            background: #fff;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            padding: 35px;
            width: 97.5%;
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $heading;
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    color: #696873;
                }
                small {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    float: right;
                    cursor: pointer;
                    img {
                        vertical-align: middle;
                    }
                }
            }
            .legend-box {
                display: flex;
                margin-top: 15px;
                .legend {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    margin-right: 12px;
                    .legend-circle {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                    }
                }
            }
            .section-rank {
                width: 50%;

                h2 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 128px;
                    line-height: 154px;
                    color: $heading;
                }
            }
            .program-rank {
                width: 50%;
                .chart-cards {
                    flex: 1;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    height: 103px;
                    text-align: center;
                    // transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                    position: relative;
                    display: flex;
                    z-index: 1;
                    .current-subjects {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #f7fafa;
                        padding: 20px;
                        p {
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0px;
                            color: #696873;
                            text-align: center;
                        }
                    }
                    h4 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 48px;
                        background-color: $chart-color;
                        color: #fff;
                        font-family: Lato;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px;
                        border-radius: 4px;
                    }

                    h5 {
                        margin-top: 10px;
                        font-size: 39px;
                        font-weight: 700;
                        font-family: "Roboto", sans-serif;
                        text-align: center;

                        img {
                            float: right;
                            margin-top: -3px;
                        }
                    }
                }
            }
            .subject-chart {
                .highcharts-axis-line {
                    display: none !important;
                }
            }
        }

        .iput {
            width: 3.5%;
            border-radius: 4px;
            border: 1px solid #9b9aa3;
            padding: 0.25rem 0.3rem;
            text-align: center;
        }
        .user-pagination {
            display: flex;
            align-items: center;
            margin-top: 15px;
            font-size: 14px;
            img {
                vertical-align: middle;
                cursor: pointer;
            }
            div {
                display: flex;
                align-items: center;
                width: 110px;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;

                input {
                    width: 30%;
                    border-radius: 4px;
                    border: 1px solid #9b9aa3;
                    padding: 0.25rem 0.3rem;
                    text-align: center;
                }
            }
            .go-btn {
                border: none;
                color: $button-color;
                font-weight: 600;
                background-color: transparent;
                border-radius: 4px;
                padding: 4px;
            }
        }
    }
}

@media (max-width: 767px) {
    .report-holder {
        padding-left: 10px;
        margin-bottom: 50px;
        .report-tabs {
            .report-tab {
                width: 30%;
                margin-right: 10px;
            }
        }
        .report-filters {
            select {
                width: 50%;
            }
        }
        .center-table {
            .table-admin {
                width: 100%;
                min-height: 345px;
                overflow: scroll;
                table tr th:first-child {
                    z-index: 3 !important;
                }
            }
            table th,
            table td {
                white-space: nowrap;
                padding: 10px 20px;
            }
            table tr th:first-child,
            table td:first-child {
                position: sticky;
                width: 100px;
                min-width: 130px;
                max-width: 130px;
                left: -1px;
                z-index: 3;
                background: #fff;
                overflow: hidden;
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
            }

            table tr th {
                position: sticky;
                top: 0;
                z-index: 1;
                background: #fff;
            }
        }
    }
}

//Admin report

.Heading2Char {
    color: #696873 !important;
}
.reports-holder {
    margin-left: 5px;
    h3 {
        font-family: Roboto;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: left;
        color: $heading;
    }
    .program-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #696873;
        margin-left: 30px;
    }
    h3 {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #484750;
    }
    .report-btn {
        margin-top: 30px;
        width: 170px;
        height: 40px;
        background-color: $button-color;
        color: #fff;
        border: none;
        text-align: center;
        border-radius: 4px;
    }
    .content-tabs {
        margin-top: 5px;
        font-family: Roboto;
        h4 {
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
        }
        button {
            background: #f7f7fa;
            border: 1px solid #dddde2;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #696873;
            // border: none;
            padding: 7px;
            min-width: 120px;
            border-radius: 4px;
        }
        .active-tab {
            background: $dark-background;
            border: none;
            color: #fff;
            padding: 7px;
            min-width: 120px;
            font-weight: bold;
        }
    }
    .center-reports {
        width: 95%;
        .center-btns {
            font-family: Roboto;
            h4 {
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
            }
            button {
                margin-top: 10px;
                margin-bottom: 7px;
                background: #f7f7fa;
                border: 1px solid #dddde2;
                color: #484750;
                font-weight: noraml;
                font-size: 14px;
                line-height: 18px;
                // border: none;
                padding: 7px 31px;
                border-radius: 4px;
            }
            .active-btn {
                background: $dark-background;
                border: none;
                color: #fff;
                font-weight: bold;
            }
        }
        .center-graph {
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $darker-background;
            }
            select {
                width: 20%;
            }
            .legands {
                // width: 20%;

                .legand-1 {
                    display: flex;
                    align-items: center;
                    div {
                        width: 24px;
                        height: 24px;
                        background: #00b24b;
                    }
                    p {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #696873;
                    }
                }
            }

            .highcharts-data-label-color-0,
            .highcharts-data-label-color-1 {
                text {
                    fill: #696873 !important;
                    font-family: Lato;
                    font-size: 12px !important;
                    color: #696873 !important;
                }
            }
            .highcharts-xaxis-labels {
                text {
                    fill: #696873 !important;
                    font-family: Roboto;
                    font-size: 12px !important;
                    color: #696873 !important;
                }
            }
        }
        .program-graph {
            background: #fff;
            padding: 20px;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            .highcharts-title {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold !important;
                font-size: 18px !important;
                line-height: 24px;
                color: $darker-background !important;
                fill: $darker-background !important;
            }
            .highcharts-subtitle {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal !important;
                font-size: 14px !important;
                line-height: 24px;
                color: $darker-background !important;
                fill: $darker-background !important;
            }
            .highcharts-data-label {
                text {
                    fill: #696873 !important;
                    font-family: Roboto !important;
                    font-size: 14px !important;
                    color: #696873 !important;
                    font-style: normal;
                    line-height: 18px;
                    text-align: center;
                }
            }
            .highcharts-xaxis-labels text {
                font-family: Roboto !important;
                font-style: normal;
                font-weight: normal;
                font-size: 14px !important;
                line-height: 18px;
                color: #696873 !important;
                fill: #696873 !important;
            }
            .program-legand {
                width: 100%;
                display: flex;
                // justify-content:center;
                align-items: center;

                .legand {
                    height: 20px;
                    width: 20%;
                }
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    width: 80%;
                }
            }
        }
        .reports-header {
            background-color: #fff;
            margin-top: 10px;
            padding: 20px;
            position: relative;
            .content-1 {
                background-color: #fff;
                width: 180px;
                height: 80px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                padding: 16px 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 15px;
                img {
                    width: 56px;
                    height: 56px;
                }
                h2 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 24px;
                    color: #484750;
                    text-align: center;
                    p {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 14px;
                        color: #696873;
                    }
                }
            }
            .header-width {
                width: 68%;
                .css-2b097c-container {
                    .css-1okebmr-indicatorSeparator {
                        display: none;
                    }
                    .css-1pahdxg-control {
                        box-shadow: 0px 0px 0px 1px $chart-color !important;
                    }
                    input:focus {
                        box-shadow: none !important;
                    }
                }
            }
            h3 {
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $darker-background;
            }
            .collapse-topics {
                // width: 21.5%;
                padding: 5px 0px;
                // padding-right: 10px;
                background: "#fff";
                h1 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #222222;
                    margin-left: 20px;
                }
                .accordion .card-header:after {
                    content: url("../../../assets/arrow-up.svg");
                    width: 15px;
                    position: absolute;
                    float: left;
                    top: 13px;
                    cursor: pointer;
                    // background-image: url('../../images/arrow-down.svg');
                }
                .accordion .card-header.collapsed:after {
                    /* symbol for "collapsed" panels */
                    content: url("../../../assets/arrow-down.svg");
                    width: 15px;
                    position: absolute;
                    top: 13px;
                    cursor: pointer;
                }
                .non-arrow {
                    &::after {
                        display: none;
                    }
                }
                .accordion {
                    .card {
                        border: none;
                        border-radius: 0px;
                        overflow: unset;
                        &:not(:last-of-type) {
                            border-bottom: 5px solid #f6f6fa;
                        }
                        a {
                            color: #696873;
                        }
                    }
                    .card-header {
                        background-color: #fff;
                        border-bottom: none;
                        margin-bottom: 0px;
                        .card-title {
                            h3 {
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 18px;
                                color: #696873;
                            }
                            p {
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #696873;
                            }
                        }
                    }
                    .card-body {
                        padding: 0rem;
                    }
                }
                .content-holder {
                    list-style: none;
                    max-height: calc(100vh - 12rem);
                    overflow: unset;

                    .resource {
                        display: flex;
                        min-height: 30px;
                        width: 100%;
                        padding: 5px;
                        // position: relative;
                        // overflow-x: hidden;
                        justify-content: flex-start;
                        align-items: flex-start;
                        cursor: pointer;
                        .recent-title {
                            margin-left: 17px;
                            width: 90%;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: #696873;
                            line-height: 18px;
                            // flex-direction: column;
                            // justify-content: space-between;
                            /* align-items: baseline; */
                            label {
                                // width: 50%;
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color: #696873;
                            }

                            input,
                            select {
                                height: 32px;
                                border: 1px solid $chart-color;
                                position: relative;
                            }
                            .list-container {
                                position: absolute;
                                z-index: 1;
                                width: 100%;
                                background: #fff;
                                box-shadow: 0px 5px 7px 2px #0000001f;
                                text-align: left;
                                max-height: 200px;
                                overflow: auto;
                                overflow-x: hidden;
                                i {
                                    margin-right: 7px;
                                }
                                ul {
                                    li {
                                        display: block;
                                        cursor: pointer;
                                        width: 100%;
                                        padding: 10px 0;
                                        border-bottom: 1px solid #00000026;
                                        margin: 0;
                                        padding-left: 11px;
                                        font-size: 14px;
                                        font-family: Roboto;
                                        &:hover {
                                            background-color: #ddd;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .center-table {
            // margin-top: 6px;
            background-color: #fff;

            .table td {
                padding: 0.75rem;
                vertical-align: top;
                border-top: 2px solid #dee2e6;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                &:first-child {
                    color: $button-color;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    cursor: pointer;
                }
            }
            .table thead th {
                vertical-align: bottom;
                text-align: center;
                border-bottom: 0px solid #dee2e6;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                border-top: 0px solid #dee2e6;
                padding: 0.75rem;
            }
        }
        .student-graph {
            background: #fff;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            padding: 35px;
            width: 97.5%;
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 24px;
                color: $darker-background;
                p {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: left;
                    color: #696873;
                }
                small {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: $button-color;
                    float: right;
                    cursor: pointer;
                    img {
                        vertical-align: middle;
                    }
                }
            }
            .legend-box {
                display: flex;
                margin-top: 15px;
                .legend {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 15px;
                    color: #696873;
                    margin-right: 12px;
                    .legend-circle {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                    }
                }
            }
            .section-rank {
                width: 50%;

                h2 {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 128px;
                    line-height: 154px;
                    color: $darker-background;
                }
            }
            .program-rank {
                width: 50%;
                .chart-cards {
                    flex: 1;
                    cursor: pointer;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    height: 103px;
                    text-align: center;
                    // transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
                    position: relative;
                    display: flex;
                    z-index: 1;
                    .current-subjects {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        background-color: #f7fafa;
                        padding: 20px;
                        p {
                            font-family: Roboto;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            letter-spacing: 0px;
                            color: #696873;
                            text-align: center;
                        }
                    }
                    h4 {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 40px;
                        line-height: 48px;
                        background-color: $chart-color;
                        color: #fff;
                        font-family: Lato;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 20px;
                        border-radius: 4px;
                    }

                    h5 {
                        margin-top: 10px;
                        font-size: 39px;
                        font-weight: 700;
                        font-family: Roboto, sans-serif;
                        text-align: center;

                        img {
                            float: right;
                            margin-top: -3px;
                        }
                    }
                }
            }
            .subject-chart {
                .highcharts-axis-line {
                    display: none !important;
                }
            }
        }
        .sem-header {
            // border-top: rgba(204, 204, 204, 0.48) solid 1px;
            margin-bottom: 0;
            // background-color: #fff;
            overflow: visible;
            left: 0;
            width: 100%;
            button.tab-button {
                top: 13px;
                transform: none;
            }
            p {
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: 0px;
                color: #222222;
            }
            ul {
                display: flex;
                overflow: auto;
                white-space: nowrap;
                padding: 5px;
                width: 100%;
                list-style-type: none;
                justify-content: center;
                align-items: center;
                li {
                    padding: 5px 0;
                    cursor: pointer;
                    width: 8%;
                    border: 0.5px solid #ddd;
                    border-radius: 4px;
                    margin-right: 5px;
                    color: #222222;
                    text-align: center;
                    font-weight: 600;
                    font-family: Roboto;
                    box-shadow: -1px 1px 2px rgba(38, 38, 38, 0.2);
                }
            }
        }
        .Tab-wrapper {
            position: relative;
            // padding: 0 27px;
            .tab-button:hover {
                cursor: pointer;
            }
            .tab-button-right {
                right: 5px;
            }
            .tab-button {
                appearance: none;
                background: transparent;
                padding: 0;
                border: 0;
                position: absolute;
                /* top: 50%; */
                transform: translateY(-50%);
                width: 12px;
            }
        }
        .active-sem {
            background-color: $button-color;
            color: #fff !important;
            border-radius: 4px;
            box-shadow: none !important;
        }
        .content-consume {
            .legand-box {
                display: flex;
                justify-content: space-between;
                background-color: #fff;
                .legend-circle {
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                }
                h3 {
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 23px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $dark-background;
                    p {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        text-align: left;
                        color: #696873;
                    }
                }

                .legands {
                    list-style: none;
                    padding: 8px;
                    background-color: #f7f7fa;
                    border-radius: 4px;
                    li {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        color: #696873;
                        font-size: 12px;
                        .div-width {
                            width: 12px;
                            height: 12px;
                            border-radius: 50px;
                        }
                    }
                }
            }

            .subject-consume {
                background-color: #fff;
                height: 100%;
                padding: 20px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                // .highcharts-axis-line {
                //     display: none !important;
                // }
                .student-trend {
                    margin-top: 15%;
                    .login-percent {
                        text-align: center;
                        h4 {
                            color: #4eb966;
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 47px;
                            line-height: 65px;
                        }
                        span {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 16px;
                            color: #696873;
                        }
                    }
                    .circle {
                        border-radius: 50%;
                        width: 55px;
                        height: 55px;
                        color: #fff;
                        margin-right: 5px;
                        font-size: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        font-weight: bold;
                    }
                }
                .highcharts-label text {
                    font-family: Lato;
                    font-size: 14px !important;
                    font-weight: 700 !important;
                    fill: #696873 !important;
                }
                .subject-chart {
                    margin-top: 15px;
                }
                .engagement-update {
                    position: absolute;
                    top: 50%;
                    z-index: 1;
                    left: 0px;
                    /* left: 0; */
                    /* right: 0; */
                    width: 100%;
                    text-align: center;
                }
            }
            .month-consume {
                background-color: #fff;
                height: 100%;
                padding: 20px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                .highcharts-legend-box {
                    fill: #f7f7fa !important;
                    border-radius: 4px;
                }
                .highcharts-axis-line {
                    display: none !important;
                }
                .engagement-update {
                    position: absolute;
                    top: 50%;
                    z-index: 1;
                    left: 0px;
                    /* left: 0; */
                    /* right: 0; */
                    width: 100%;
                    text-align: center;
                }
                .highcharts-legend-item text {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: 400 !important;
                    line-height: 15px;
                    color: #696873 !important;
                }
                .subject-chart {
                    margin-top: 15px;
                }
            }
            .subject-consume1 {
                height: 100%;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                .legend-status {
                    display: flex;
                    padding: 20px;
                    padding-top: 0px;
                    background-color: #fff;
                    .legend {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-weight: normal;
                        font-size: 12px;
                        line-height: 15px;
                        color: #696873;
                        margin-right: 12px;
                        .legend-circle {
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                        }
                    }
                }
                .progres-test {
                    // margin-top: 15px;
                    // position: relative;
                    padding: 20px;
                    background-color: #fff;
                    .card {
                        border: none;
                        border-radius: 4px;
                        height: 225px;
                        margin-top: 1px;
                        margin-left: 2px;
                        border: none;
                        width: 95% !important;
                        // margin-right: 10px;
                        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                        border-radius: 4px;
                        cursor: pointer;
                        .card-body {
                            .card-overlay {
                                position: absolute;
                                z-index: 3;
                                width: 100%;
                                height: 100%;
                            }
                        }
                        h1 {
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 18px;
                            color: #222222;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                        .details {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 18px;
                            padding: 6px;
                        }
                        .status {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            width: 90%;
                            margin-top: 15px;
                            h2 {
                                font-family: Lato;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 24px;
                                line-height: 29px;
                                color: #ecc30b;
                            }
                            h3 {
                                font-family: Lato;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 17px;
                                color: #696873;
                                margin-left: -12px;
                            }
                        }
                    }

                    .slick-list {
                        padding-bottom: 90px;
                    }
                    .slick-track {
                        display: flex !important;
                        align-items: stretch;
                    }

                    .slick-slide {
                        height: 210px !important;
                    }
                    .slick-slide {
                        // width: 175px!important;
                        margin-right: 1px;
                        &:last-child {
                            margin-right: 40px;
                        }
                    }
                    .slick-disabled {
                        opacity: 0% !important;
                    }

                    .slick-next {
                        position: absolute;
                        right: 25px;
                        top: 30%;
                        width: 0px;
                        height: 0px;
                        &:before {
                            font-size: 0px;
                            opacity: 0;
                            display: none;
                        }
                    }
                    .slick-prev {
                        position: absolute;
                        left: -25px;
                        top: 30%;
                        width: 0px;
                        height: 0px;
                        z-index: 1;
                        &:before {
                            opacity: 0;
                            display: none;
                            font-size: 0px;
                        }
                    }

                    .active-subject {
                        // background-color: $dark-background !important;
                        border: 2px solid $button-color;
                        border-color: $button-color;
                        position: relative;
                    }
                }

                .highcharts-label text {
                    font-family: Lato;
                    font-size: 14px !important;
                    font-weight: 600 !important;
                    fill: $darker-background !important;
                }
                // Circular progress bar
                .circular-progresss {
                    position: relative;
                    width: 300px;
                    .circular-progress2 {
                        width: 300px;
                        position: absolute;
                        top: 12.8%;
                        left: 0%;
                    }
                }
            }

            .engagement-graph {
                background-color: #fff;
                height: 100%;
                position: relative;
                padding: 20px;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                border-radius: 4px;
                h3 {
                    font-family: Roboto;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 23px;
                    letter-spacing: 0px;
                    text-align: left;
                    color: $dark-background;
                    small {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        color: $button-color;
                        float: right;
                        cursor: pointer;
                        img {
                            vertical-align: middle;
                        }
                    }
                }
                .engagement-score {
                    h3 {
                        font-family: Lato;
                        font-size: 48px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 58px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: #4eb966;
                    }
                    p {
                        font-family: Roboto;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px;
                        letter-spacing: 0px;
                        text-align: center;
                        color: #696873;
                    }
                }
                .engagement-chart {
                    .highcharts-data-label text {
                        fill: $darker-background !important;
                        color: $darker-background !important ;
                        font-size: 14px !important;
                        font-weight: 600 !important;
                        font-family: Lato !important;
                    }
                    .highcharts-axis-line {
                        stroke: #efb118 !important;
                    }
                    .highcharts-point {
                        fill: #e56e25 !important;
                    }
                    .engagement-update {
                        position: absolute;
                        top: 40%;
                        z-index: 1;
                        left: 0px;
                        /* left: 0; */
                        /* right: 0; */
                        width: 100%;
                        text-align: center;
                    }
                }
                .graph-note {
                    text-align: right;
                    padding-right: 10px;
                    span {
                        font-size: 12px;
                        font-style: italic;
                        color: #b1a6a6;
                    }
                }
            }
        }
        .iput {
            width: 3.5%;
            border-radius: 4px;
            border: 1px solid #9b9aa3;
            padding: 0.25rem 0.3rem;
            text-align: center;
        }
        .user-pagination {
            display: flex;
            align-items: center;
            margin-top: 15px;
            font-size: 14px;
            img {
                vertical-align: middle;
                cursor: pointer;
            }
            div {
                display: flex;
                align-items: center;
                width: 130px;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #696873;

                input {
                    width: 30%;
                    border-radius: 4px;
                    border: 1px solid #9b9aa3;
                    padding: 0.25rem 0.3rem;
                    text-align: center;
                }
            }
            .go-btn {
                border: none;
                color: $button-color;
                font-weight: 600;
                background-color: transparent;
                border-radius: 4px;
                padding: 4px;
            }
        }
    }
    .test-wrapper {
        background-color: #fff;
        box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        width: 95%;
        padding: 15px;
        margin-top: 20px;
    }
    .completion-details {
        .test-wise-details {
            ul {
                list-style: none;
                li {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #fff;
                    margin-top: 4px;
                    padding: 10px 15px;
                    border: 1px solid #ddd;
                    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                    &:first-child {
                        margin-top: 0px;
                    }
                    .topic-name {
                        // flex: 1;
                        width: 20%;
                    }
                    .topic-time {
                        display: flex;
                        width: 20%;
                        p {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            font-family: Lato;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: $dark-background;
                            margin-right: 20px;
                            span {
                                color: #9b9aa3;
                                //styleName: Caption;
                                font-family: Roboto;
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 15px;
                                letter-spacing: 0px;
                            }
                        }
                    }
                    .last-taken {
                        display: flex;
                        width: 15%;
                        p {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 15px;
                            display: flex;
                            align-items: center;
                            color: #9b9aa3;
                        }
                    }
                    .last-score {
                        width: 10%;
                        p {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 20px;
                            line-height: 18px;
                            color: $dark-background;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-direction: column;
                            span {
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                color: #9b9aa3;
                                margin-top: 2px;
                            }
                        }
                    }
                    .last-history {
                        display: flex;
                        width: 10%;
                        cursor: pointer;
                        p {
                            font-family: Lato;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            letter-spacing: 0px;
                            text-align: left;
                            color: #9b9aa3;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .empty-test {
                background-color: #fff;
                width: 100%;
                padding: 52px;
                right: 0px;
                left: 1px;
                margin-top: 10px;
                .no-test {
                    h4 {
                        font-weight: 600;
                    }
                    p {
                        padding: 10px;
                        text-align: center;
                        width: 350px;
                    }
                }
            }
        }
        .accordion .card-header:after {
            content: url("../../../assets/arrow-up.svg");
            width: 15px;
            margin-right: 15px;
            float: right;
            margin-top: -23px;
            cursor: pointer;
        }
        .accordion .card-header.collapsed:after {
            content: url("../../../assets/arrow-down.svg");
            width: 15px;
            margin-right: 15px;
            margin-top: -17px;
            cursor: pointer;
        }
        .accordion {
            .card {
                border: none;
                &:not(:last-of-type) {
                    border-bottom: none;
                }
                &:first-child {
                    border-top: 1px solid #dddde2;
                }
                border-bottom: 2px solid #f6f6f6 !important;
            }
            .card-header {
                background-color: #fff;
                border-bottom: none;
                padding: 1rem 0.95rem;

                .card-title {
                    p {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        color: #484750;
                        margin-left: 20px;
                    }
                    h6 {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #484750;
                    }
                    img {
                        width: 16px;
                        height: 16px;
                    }
                    h5 {
                        font-family: Lato;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 12px;
                        color: #5fbb97;
                    }
                }
            }
            .card-body {
                padding: 0rem;
                position: relative;
            }
            a {
                color: #222222;
            }
        }
        .content-holder {
            list-style: none;
            // max-height: calc(100vh - 12rem);
            // overflow-y: auto;

            & ::-webkit-scrollbar-track {
                border-radius: 10px;
                background-color: white;
            }
            &::-webkit-scrollbar {
                width: 6px;
                background-color: #ffffff;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                max-height: 10px;
                background-color: #e9e8e8;
            }

            .resource {
                display: flex;
                min-height: 50px;
                width: 100%;
                padding: 15px 50px 10px 15px;
                position: relative;
                overflow-x: hidden;
                justify-content: flex-start;
                align-items: flex-start;
                cursor: pointer;
                background-color: #f7f7fa;
                .recent-title {
                    margin-left: 18px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    // align-items: center;
                    color: #222222;
                    h1 {
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 18px;
                        color: #484750;
                        width: 30%;
                    }
                    h2 {
                        width: 20%;
                        text-align: center;
                        font-size: 16px;
                        line-height: 19px;
                        span {
                            color: #222222;
                        }
                        p {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 19px;
                            color: #00b24b;
                        }
                    }
                    .quiz-count {
                        width: 96px;
                        padding: 5px;
                        background-color: #fff;
                        border-radius: 4px;
                        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                        span {
                            font-family: Roboto;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 17px;
                            color: #696873;
                            small {
                                img {
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.details-modal {
    .sticky-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }
    table {
        .deco {
            text-decoration: underline !important;
            cursor: pointer;
        }
        td {
            text-align: center;
        }
    }
}
.history-popup {
    position: fixed;
    top: 0;
    background-color: #fff;
    padding: 5px;
    width: 400px;
    z-index: 12;
    right: 0px;
    box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
    .history-header {
        position: relative;
        padding: 8px;
        h1 {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: $darker-background;
        }
        .cross-holder {
            position: absolute;
            right: 10px;
            top: 12px;
            cursor: pointer;
        }
    }
    #scrollableDiv1 {
        & ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: white;
        }
        &::-webkit-scrollbar {
            width: 6px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            max-height: 10px;
            background-color: #e9e8e8;
        }
    }
    .history-details {
        background: #ffffff;
        // min-height: 70vh;
        margin-top: 0px;
        position: sticky;
        position: -webkit-sticky;
        top: 150px;
        .reward-entry {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 12px 20px;
            padding-right: 30px;
            align-items: center;
            border-bottom: 1px solid #dee2e6;
            h1 {
                font-family: Roboto;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 18px;
                color: #696873;
                img {
                    width: 15px;
                    height: 15px;
                    vertical-align: middle;
                }
            }

            .credit {
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
.ready-modal {
    padding: 20px;
    h1 {
        color: #696873;
    }
    button {
        margin-top: 15px;
        background-color: $button-color;
        border: none;
        color: #fff;
        padding: 5px 50px;
        border-radius: 4px;
    }
}

@media (max-width: 767px) {
    .reports-holder {
        margin-left: 0px;
        margin-bottom: 30px;
        .history-popup {
            width: 100% !important;
        }
        .content-tabs {
            display: flex;
            overflow: auto;
            // &::-webkit-scrollbar {
            //     display: none;
            // }
            button {
                padding: 7px 25px;
                // width: unset;
                min-width: 165px;
            }
            .active-tab {
                // padding: 7px 25px;
                // width: unset;
                min-width: 165px;
            }
        }
        .center-reports {
            width: unset;
            margin-left: -14px;
            .sem-header {
                ul {
                    justify-content: flex-start;
                    padding: 0px;
                    padding-bottom: 10px;
                }
            }
            .center-btns {
                margin-left: 18px;
            }
            .reports-header {
                padding: 20px 15px;
                .header-width {
                    width: 100%;
                    flex-wrap: wrap;
                }
                h3 {
                    width: 75%;
                }
                .content-1 {
                    width: 150px;
                    margin-top: 10px;
                }
            }
            .center-table {
                margin-bottom: 20px;
                table {
                    text-align: center;
                }
                table tr th:first-child {
                    z-index: 5 !important;
                }
                .table-admin {
                    width: 100%;
                    min-height: 100px;
                    overflow: scroll;
                }
                table th,
                table td {
                    white-space: nowrap;
                    padding: 10px 20px;
                }
                table tr th:first-child,
                table td:first-child {
                    position: sticky;
                    width: 100px;
                    min-width: 130px;
                    max-width: 130px;
                    left: -1px;
                    z-index: 5;
                    background: #fff;
                    overflow: hidden;
                    // display: -webkit-box;
                    // -webkit-line-clamp: 2;
                    // -webkit-box-orient: vertical;
                }

                table tr th {
                    position: sticky;
                    top: 0;
                    z-index: 4;
                    background: #fff;
                }
            }
            .iput {
                width: 8%;
            }
            .user-pagination {
                margin-bottom: 20px;
            }
            .iput {
                width: 8%;
            }
            .center-graph {
                padding: 15px;
                .legands {
                    width: 50%;
                }
            }
            .program-graph {
                padding: 15px;
                .program-legand {
                    width: 100%;
                    flex-wrap: wrap;
                    // flex-direction: row;
                    .legand {
                        width: 45%;
                    }
                }
            }
            .student-graph {
                width: 100%;
                .program-rank .chart-cards h4 {
                    padding: 40px;
                    width: 30%;
                }
            }
            .content-consume {
                margin-right: -15px;
                .subject-consume {
                    padding: 15px;
                    .student-trend {
                        margin-top: 0%;
                    }
                }
                .subject-consume1 {
                    box-shadow: none;
                    padding: 0px;
                    .legend-status {
                        padding: 0px 20px;
                    }
                    .progres-test {
                        padding: 20px 30px;
                        .mobile-progress {
                            width: unset;
                            min-height: 290px;
                            padding: 0px 0px;
                            .card {
                                min-width: 185px;
                                margin-right: 15px;
                                .card-body {
                                    .card-overlay {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .content-tabs {
                            margin-left: -10px;
                            margin-top: 15px;
                            button {
                                width: 140px;
                                // height: 32px;
                                padding: 7px 20px;
                                font-family: Roboto;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                    .completion-details {
                        .accordion .card-header {
                            padding: 10px 0px;
                            .card-title {
                                p {
                                    margin-left: 5px;
                                }
                            }
                        }
                        .content-holder .resource {
                            .recent-title {
                                margin-left: 5px;
                                h1 {
                                    width: 100%;
                                }
                                h2 {
                                    margin-left: 20px;
                                    width: 25%;
                                    &:first-child {
                                        margin-left: 0px;
                                    }
                                }
                                .quiz-count {
                                    padding: 10px 20px;
                                }
                            }
                        }
                        .test-wise-details {
                            // padding-left: 15px;
                            ul {
                                li {
                                    justify-content: flex-start;
                                    padding: 15px 10px !important;
                                    .last-taken1 {
                                        h3 {
                                            font-family: Roboto;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 700;
                                            line-height: 18px;
                                            letter-spacing: 0px;
                                            text-align: left;
                                            color: #484750;
                                        }
                                        p {
                                            font-family: Roboto;
                                            font-size: 12px;
                                            font-style: normal;
                                            font-weight: 400;
                                            line-height: 15px;
                                            letter-spacing: 0px;
                                            text-align: left;
                                            color: #9b9aa3;
                                        }
                                    }
                                }
                            }

                            .empty-test {
                                padding-bottom: 80px;
                                padding-top: 35px;
                                width: unset;
                                margin-right: -13px;
                                margin-left: -13px;
                                .no-test {
                                    p {
                                        padding: 10px 30px;
                                        text-align: justify;
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }
                .month-consume {
                    margin-right: -15px;
                    padding: 15px 22px;
                }
                .month-consume1 {
                    margin-right: -15px;
                    padding: 15px;
                }
            }
            .col-md-6 {
                padding-left: 0px;
                padding-right: 0px;
            }
        }
    }

    .test-wrapper {
        width: unset !important;
        margin-left: -18px;
        padding: 11px !important;
        .test-wise-details {
            ul {
                li {
                    padding: 10px 0px !important;
                    h3 {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
    .update-modal1 {
        width: unset;
    }
}
